import React from 'react'
import { Section } from '../../components/Section'
import styled from 'styled-components'
import IqBgImg from '../../svg/iq.svg'
import IbgImg from '../../images/l.png'
import QbgImg from '../../images/q.png'
import BreakpointDown from '../../components/Media/BreakpointDown'
import { StaticImage } from 'gatsby-plugin-image'
import Video from '../../components/video'

const ShadowWrapper = styled.div`
  padding-bottom:0px;
  position:relative;
  z-index:2;
  .container{display:flex; align-items:center;} 
  @media(min-width:2200px){
    min-height:80vh;
    display:flex;
  }
`
const Philosophy = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-left:-45px;
  margin-right:-45px;
  justify-content:space-between;
  min-height: 800px;
  padding-bottom:80px;
  position:relative; 
  align-items:center;
 ${BreakpointDown.xl`
    flex-direction: column-reverse;
    margin-left:-15px;
    margin-right:-15px;
 `}
    
 ${BreakpointDown.lg`
    margin-left:-15px;
    margin-right:-15px;
    text-align:center;
 `}
 ${BreakpointDown.md`
    text-align:center; 
 `}

 z-index:2;
 &:before{
    content:'';
    background:url(${IqBgImg}) center right no-repeat;
    background-position: 97% 100%;
    position:absolute;
    left:0;
    top: -16px;
    bottom:0;
    right:0;
    background-size:contain;
   ${BreakpointDown.xl`
     display:none;
   `}
   @media(min-width:2200px){
    background-position: 92% 100%;
   }
 }

 iframe{
   width:640px;
   height:360px;
 }
`
const LeftWrapper = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:45px;
 padding-right:45px;
 position: relative;
 align-self: center;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%;
  padding-left:15px;
  padding-right:15px;
 &:before{
    content:'';
    background:url(${IbgImg}) center right no-repeat;
    background-position: center center;
    position: absolute;
    left: 12px;
    top: 0;
    bottom:0px;
    right: 0;
    background-size: 20%;
    ${BreakpointDown.xl`
     display:none;
    `}
    ${BreakpointDown.md`
      background-position: top center;
      background-size: 48%;
    `}
 }
 `}
`
const InnerContent = styled.div`
    position: relative;
    z-index:1;
    padding-top:50px;
    padding-bottom:50px;
    ${BreakpointDown.lg`
      padding-top:30px;
      padding-bottom:30px;
    `}
`
const RightWrapper = styled.div`
  flex:0 0 50%;
  width:50%;
  padding-left:45px;
  padding-right:45px; 
  position:relative;
 ${BreakpointDown.xl`
    flex:0 0 100%;
    width:100%;
    padding-left:15px;
    padding-right:15px;
  &:before{
    content:'';
    background:url(${QbgImg}) center right no-repeat;
    background-position: bottom center;
    position: absolute;
    left: 0;
    top: 40px;
    bottom: -90px;
    right: 0;
    background-size: 57%;
    ${BreakpointDown.lg`
    background-size: 54% 94%;
    bottom: -90px;
   `}
    ${BreakpointDown.md`
    bottom: -107px;
    background-size: 100% 100%;
   `}
  }
  ${BreakpointDown.md`
     padding-top: 0px;
  `}
 `}

`
const OurphilosophyTitle = styled.div`
   font-weight:900;
   line-height:1;
`
const QualityTitle = styled.h1`
  color:#529A00;
  font-weight:900;
  line-height:1;
  margin-bottom:5px;
  margin-top:5px;
  text-transform:uppercase;
  letter-spacing: 1.93px;
  ${BreakpointDown.md`
    letter-spacing: 0; 
  `}
`
const ProvideQualityTitle = styled.div`
 font-weight:900;
 line-height:1;
`
const PhilosophyContent = styled.div`
  color: #333333;
  margin-top:48px;
  margin-bottom:48px;
  font-size:16px;
`
const ImgWrapper = styled.div`
  text-align: center;
  position:relative;
`
const ProductClinetCount = styled.div`
 display:flex;
 position:relative;
 z-index:4;
 ${BreakpointDown.lg`
  justify-content:center;
 `}
`

const CountItem = styled.div`
  display:flex;
  color:#fff;
  align-items:center;
& + & {
  margin-left:40px;
  ${BreakpointDown.xl`
    margin-left:20px;
  `}
   ${BreakpointDown.sm`
    margin-left:12px;
  `}
}

`
const Count = styled.div`
  color:#fff;
  margin-bottom:0;
  ${BreakpointDown.xl`
    font-size:32px;
  `}
   ${BreakpointDown.md`
    font-size:16px;
  `}
`
const CountType = styled.div`
  font-size:12px;
  margin-left:8px;
`

const MonitoringSolutionsBottomWrapper = styled.div`
 position:relative;
 background:#529A00;
 z-index: 2;
 margin-top:-100px;
${BreakpointDown.lg`
    padding-top:10px;
    padding-bottom:10px;
`}
`

const MonitoringSolutionsBottom = styled.div`
  display:flex;
  flex:0 0 100%;
  width:100%;
  font-size:20px;
  font-family: 'Comic Neue', cursive;
  z-index:2;
  height:120px;
  margin-left:-15px;
  margin-right:-15px;
  align-items:center;
 ${BreakpointDown.lg`
  flex-wrap:wrap;
  margin-left:0;
  margin-right:0;
  height:auto;
 `}

`
const MonitoringSolutionsBottomLeft = styled.div`
  flex: 0 0 50%;
  padding:10px 15px;
  color:#FFFFFF;
  position:relative;
  ${BreakpointDown.lg`
   flex: 0 0 100%;
   text-align:center;
   padding:5px 15px;
  `}
`
const MonitoringSolutionsBottomRight = styled.div`
  flex:0 0 50%;
  padding:15px 45px;
  color: #000000;
  font-weight:bold;
  ${BreakpointDown.lg`
    flex: 0 0 100%;
    text-align:center;
    padding:5px 15px;
  `}
  ${BreakpointDown.sm`
    font-size: 18px;
    line-height: 26px;
  `}
`
const ArrowImg = styled.div`
 position:absolute;
 left:0;
 right:0;
 bottom:-120px;
 ${BreakpointDown.xl`
   display:none;
 `}
`

const OurPhilosophyAbout = () => (
  <>
    <Section pt="0" pb="0px" xpt="40px" mpt="40px">
      <ShadowWrapper>
        <div className="container">
          <Philosophy>
            <LeftWrapper>
              <InnerContent>
                <OurphilosophyTitle className="h2">Our philosophy :</OurphilosophyTitle>
                <QualityTitle>INTEGRITY + QUALITY = IQ</QualityTitle>
                <ProvideQualityTitle className="h2">WYN is about providing quality services with integrity!</ProvideQualityTitle>
               
                <PhilosophyContent><p><strong>WYN Technologies</strong> is here to provide <strong>Whatever You Need</strong>, and we’re proud to do so with both <strong>Integrity & Quality</strong> – we call that our <strong>IQ</strong> philosophy. No matter what your project requires, we’re here to take care of your needs. Plus, we’ll take care of <strong>you</strong> the right way, too! </p>
                  <p>At <strong>WYN</strong>, we’re about more than just taking care of your technology installation, operation, & maintenance services in a timely, professional manner. We’re ultimately here for the good of your business. We know how corporate budgets & finances work, and we’re serious about delivering the best ROI to help optimize your business bottom line.</p>
                 <p>With over 75 years of customer service experience under our collective belts, we’ve learned a few things about the importance of following ethical principles and keeping clear channels of communication open with our clients. Our goal is to always be as transparent and clear with you as possible, and we’ll keep you well-informed about every stage of progress with your project.</p>
                </PhilosophyContent>
              </InnerContent>
            </LeftWrapper>
            <RightWrapper>
              <ImgWrapper>
                <Video
                  videoSrcURL="https://player.vimeo.com/video/400672092"
                  videoTitle="WYN Technologies Facility"
                />
                {/* <StaticImage src="../../images/vedio-thumb.png" alt="philosophy " quality={100} placeholder="blured" /> */}
                <ArrowImg>
                  <StaticImage src="../../svg/about-us-arrow.svg" alt="arrow" quality={100} placeholder="blured" />
                </ArrowImg>
              </ImgWrapper>
            </RightWrapper>
          </Philosophy>
        </div>
      </ShadowWrapper>
      <MonitoringSolutionsBottomWrapper>
        <div className="container">
          <MonitoringSolutionsBottom>
            <MonitoringSolutionsBottomLeft>
              <ProductClinetCount>
                {/* <CountItem><Count className="h1">200</Count><CountType>Products</CountType></CountItem>
                <CountItem><Count className="h1">400</Count><CountType>Our Client</CountType></CountItem>
                <CountItem><Count className="h1">400</Count><CountType>Our Client</CountType></CountItem> */}
              </ProductClinetCount>
            </MonitoringSolutionsBottomLeft>
            <MonitoringSolutionsBottomRight>
              We help you easily discover and manage your privileged projects.
            </MonitoringSolutionsBottomRight>
          </MonitoringSolutionsBottom>
        </div>
      </MonitoringSolutionsBottomWrapper>
    </Section>
  </>
)
export default OurPhilosophyAbout;