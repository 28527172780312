import React from "react"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { BreadCrumb, Section , Tags} from '../components/Section' 
import Benifits from '../components/Benifits'
import OurPhilosophyAbout from '../sections/about/philosiphy'
import WeProvideBg from '../images/we-provide.jpg'
import ContentSectionBg from '../images/about-3.jpg'
import BreakpointDown from "../components/Media/BreakpointDown"
import BreakpointUp from "../components/Media/BreakpointUp"

const TagsLarge = styled(Tags)`
  padding:10px 20px;
  background:transparent;
  border:1px solid #73A935;
  margin-bottom:15px;
  margin-bottom:15px;
  margin-right:15px;
  margin-left:15px;
  ${BreakpointDown.lg`
    margin-bottom:8px;
    margin-right:4px; 
    margin-left:4px; 
  `}
  ${BreakpointDown.md`
      padding:5px 8px;
  `}
`
const ContentTitle = styled.div`

`

const OurSolutionList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:40px;
 padding-top:40px;
 align-items:center;
 ${BreakpointDown.xl`
     margin-top:0px;
    padding-top:20px;
 `}
${BreakpointDown.lg`
    margin-top:0px;
    padding-top:20px;
    text-align:center;
  .gatsby-image-wrapper{
      margin-left:auto;
      margin-right:auto;
  }
`}
 &:nth-child(even){
  ${BreakpointUp.lg`
     flex-direction:row-reverse;
  `}
  .left{
    .large-text{ text-align:left; }
    &:before{
       left:0;
       right:auto;
       margin-left:-60px;
    }
  }
    .right{
      padding-right: 90px;
      padding-left:0;
      ${BreakpointDown.lg`
        flex:0 0 100%;
        padding-left:15px;
        padding-right:15px;
 `}
    }
  }
`
const OurSolutionListLeft = styled.div`
flex:0 0 600px;
position:relative;
 ${BreakpointDown.xl`
   flex:0 0 450px;
 `}
  ${BreakpointDown.lg`
   flex:0 0 100%;
 `}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top: 87px;;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.lg`
   display:none;
  `}
}
`
const OurSolutionListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.lg`
    flex:0 0 100%;
    padding-left:15px;
    padding-right:15px;
 `}
`
const OurSolutionListRightTitle = styled.div`
`
const OurSolutionListRightContent = styled.div`
`

const LargeText = styled.div`
  font-size:90px;
  color:#000;
  font-weight:900;
  text-transform:uppercase;
  font-family: "Nunito Sans", sans-serif;
  position: relative;
  text-align: right;
  opacity:0.06;
  margin-bottom: 10px;
  ${BreakpointDown.md`
   font-size:60px;
  `}
`


const SectionWeProvide = styled(Section)`
.container{ z-index:2;}
  &:before{
    background-size:cover;
  }
`

const WeProvide = styled.div`
display:flex;
flex-wrap:wrap;
margin-left:-15px;
margin-right:-15px;
${BreakpointDown.xl`
  text-align:center;
`}
`
const WeProvideColumn = styled.div`
 flex:0 0 35%;
 width:35%;
 padding-left:15px;
 padding-right:15px;
 color:#fff;
 ${BreakpointDown.xl`
    flex:0 0 100%;
    width:100%;
  `}
 &:last-child{
  flex:0 0 65%;
  width:65%;
  ${BreakpointDown.xl`
    flex:0 0 100%;
    width:100%;
  `}
 }
`
const WeProvideColumnTitle = styled.h2` 
  color:#fff;
  margin-bottom:20px;
`

const SectionBgAfter = styled(Section)`
  &:before{
    background-size:cover;
    background-position:center center;
    @media(max-width:1440px){
      background-size:inherit;
    }
  }
`

const Content = styled.div`
 max-width:800px;
 margin-left:auto;
 margin-right:auto;
 a{
  color:#529A00;
}
 @media(max-width:1440px){
  max-width: 710px;
 }
 @media(max-width:1280px){
  max-width: 629px;
 }
`


function AboutUsPage() {
  return (
    <Layout pageName="about-us">
      <SEO  title="About WYN Technologies | Our Philosophy and Vision" description="WYN Technologies’ customer service and our philosophy & vision is behind our success in helping businesses grow and prosper.
"/>
      <Section pt="150px" xpt="120px" mpt="60px">
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">About us</Link>
          </BreadCrumb>

        </div>
      </Section>
      <OurPhilosophyAbout />
      <Section pt="60px" pb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">

          <OurSolutionList>
            <OurSolutionListLeft className="left">
              <LargeText className="large-text">Mission</LargeText>
              <StaticImage
                src="../images/our-service-2.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight className="right">
              <OurSolutionListRightTitle className="h3">Nationwide Sales, Installation, and Technical Consultation Services</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p><strong>WYN Technologies Inc</strong> is locally based in High Point, North Carolina, but our service footprint actually extends across the continental United States! <strong>WYN</strong> is licensed to acquire low voltage permits all over North Carolina, and our licenses are reciprocally accepted in other states, as well. We also partner with electricians across the country so that we can truly deliver turnkey solutions for all of your project needs!</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft className="left">
              <LargeText className="large-text">Vision</LargeText>
              <StaticImage
                src="../images/our-service-2.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight className="right">
              <OurSolutionListRightTitle className="h3">Staging, Storage, Inventory, & Asset Management Services</OurSolutionListRightTitle>
              <OurSolutionListRightContent>
                <p>Our nearly 5,000-square-foot data facility is fully equipped with 24 staging tables, on which we can set up and test up to 240 machines! This facility is also equipped with a secure data closet, limited access control, and state-of-the-art surveillance cameras, motion detectors, and monitoring capabilities</p>
               <p>Need some secure, reliable management of your inventory and assets? The <strong>WYN</strong> warehousing facility is 6,000 square feet, with a 32-foot ceiling; we can house up to 740 pallets in this space! Our warehouse space is secured by surveillance cameras, motion sensors, & alarms, and also features limited access. We also have an additional 20,000+ square feet of secure storage space available to meet our clients’ needs</p>
              </OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>

        </div>
      </Section>
      {/* <Benifits /> */}
      <SectionWeProvide pt="170px" pb="170px" xpt="80px" xpb="80px" mpt="40px" mpb="40px" bgBefore={`url(${WeProvideBg})`}>
        <div className="container">
          <WeProvide>
            <WeProvideColumn>
              <WeProvideColumnTitle className="h1">We provide top quality services </WeProvideColumnTitle>
            </WeProvideColumn>
            <WeProvideColumn>
              <TagsLarge to="/">Project Management</TagsLarge>
              <TagsLarge to="/">Cabling</TagsLarge>
              <TagsLarge to="/">NVR/Camera</TagsLarge>
              <TagsLarge to="/">Inventory</TagsLarge>
              <TagsLarge to="/">Access Point Heat Mapping</TagsLarge>
              <TagsLarge to="/">POS Installation</TagsLarge>
              <TagsLarge to="/">Thermal Monitoring</TagsLarge>
              <TagsLarge to="/">ISP Service</TagsLarge>
              <TagsLarge to="/">Data Security</TagsLarge>
              <TagsLarge to="/">GPS Tracking</TagsLarge>
              <TagsLarge to="/">Digital Signage Installation</TagsLarge>
              <TagsLarge to="/">Telephone Systems</TagsLarge>
              <TagsLarge to="/">Access Control Installation</TagsLarge>
            </WeProvideColumn>
          </WeProvide>
        </div>
      </SectionWeProvide>
      <SectionBgAfter pt="120px" pb="120px" xpt="80px" xpb="80px" mpt="40px" mpb="40px" bgBefore={`url(${ContentSectionBg})`}>
        <div className="container">
          <Content>
            <ContentTitle className="h2">Taking Care of Your Needs is Something We Take Seriously</ContentTitle>
            <p><strong>Mission</strong>: <strong>WYN Technologies</strong> is here to provide <strong>Whatever You Need</strong> in terms of technology business solutions, and to do so with both <strong>Integrity & Quality</strong>.</p>

            <p><strong>Values</strong>: Integrity, Quality, Clear Communication, Cost-Effectiveness, and Above-&-Beyond Service & Support</p>
         
            <p>In terms of product quality, we work with some of the best-known and most-respected brands in the business, including Samsung, Sharp, NEC, Cisco, TP-Link, viLogics, Intermedia, Staff Alerter, Poly (Plantronics), CyberPower, APC, and more. These products come with excellent warranties, and we’re happy to provide technical service for everything we sell and install. We can also partner with you to provide ongoing maintenance through our “service after the sale” contracts. By choosing to take advantage, you’ll have greater peace of mind knowing your business technology will be well-maintained and will continue serving you well for years to come.</p>
            
            <p>But beyond these valuable products and services we offer, another distinctive about <strong>WYN</strong> is our commitment to be here for you whenever you may need us. We actually answer the phone when you call – with no ridiculous, cumbersome menus for you to have to scroll through – and we react swiftly to the needs of our customers and clients 24/7. How’s that for service you can count on?</p>

            <p>So, what are you waiting for? Give us a call at <a href="tel:3368990555">(336) 899-0555</a> , and let’s get the conversation started today!</p>
          </Content>
        </div>
      </SectionBgAfter>
    </Layout>
  )
}

export default AboutUsPage
